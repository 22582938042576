import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";
import Award from "../../icons/award.svg"
import Support from "../../icons/support-2.svg";
import Quote from "../../components/quote";
import Swap from "../../icons/swap.svg";
import Communicate from "../../icons/communicate.svg";
import Excellence from "../../icons/excellence.svg";

const PortfolioHeader = () => (
  <section className="bg-dark py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">Pure Flix</p>
            </div>
            <div className="col-12 mt-0 mt-lg-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">Zendesk Integration</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white mb-0">
            729 solutions helped this client navigate a challenging zendesk integration for its busy customer call
            center.
          </h2>
          <p className="text-white">Our success is shown in the numbers:</p>
          <p className="text-white"><b>Call center costs decreased by 50% and customer satisfaction increased by
            20%.</b></p>
        </div>
      </div>
    </div>
  </section>
);

const PureFlixHeader = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <StaticImage
            src="../../images/portfolio/pure-flix/pureFlix-header.jpeg"
            alt="PureFlix"
          />
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section>
    <div className="container pb-6">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>Pure Flix is the leading video streaming service for faith-focused family-friendly
            entertainment content. This company places a high value on customer service and needed help implementing
            Zendesk for their customer service management.</p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>Project Goals</h2>
          <ol className="text-primary">
            <li>
              <span className="text-dark">
                Implement Zendesk as smoothly as possible.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Solve integration problems quickly with expert help from 729 Solutions.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Lower operational costs for the Pure Flix call center.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Improve customer satisfaction with call center interactions.
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);


const DevelopmentLanguagesAndNeeds = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="mb-4">
            <h2 className="pb-3 m-0">development languages used</h2>
            <p>.Net/C#</p>
          </div>
          <div className="mb-4">
            <h2 className="pb-3 m-0">Platforms</h2>
            <p>Zendesk</p>
            <p>Mojo Helpdesk</p>
          </div>
          <div className="mb-4">
            <h2 className="pb-3 m-0">Databases</h2>
            <p>Portable LiteDB</p>
            <p>MySQL Database</p>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <h2>Core Client Needs</h2>
          <p className="m-0">
            <b>Uptime</b>
          </p>
          <p>
            Pure Flix is a streaming entertainment service, so it had to remain up and running at all times with no
            interruption in customer service for its subscribers.
          </p>
          <p className="m-0">
            <b>Implementation</b>
          </p>
          <p>
            The team needed to integrate Zendesk into Pure Flix’s existing setup quickly, smoothly, and professionally,
            solving any problems that arose.
          </p>
          <p className="m-0">
            <b>Savings and Service</b>
          </p>
          <p>
            Pure Flix hoped to decrease costs for its customer call center while at the same time improving its customer
            service ratings.
          </p>
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location}/>
        </div>
      </div>
    </div>
  </section>
);

const PartnerQuote = () => (
  <section className="bg-dark py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-7">
          <h2 className="text-white">729 is proud to be a Zendesk Implementation Premier Partner.</h2>
        </div>
        <div className="col-12 col-lg-5">
          <StaticImage
            src="../../images/partners/zendesk-implementation-premier-partner-badge.png"
            alt="Zendesk Implementation Premier Partner"
          />
        </div>
      </div>
    </div>
  </section>
)

const ProjectSkills = () => (
  <section className="bg-dark py-6">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-12 col-lg-2 p-lg-0 mb-4 mb-lg-0">
          <div className="text-center">
          <Excellence width={150} height={150} className="text-white mb-3"/>
          <h5 className="text-secondary-light ">Expertise</h5>
          </div>
        </div>
        <div className="col-12 col-lg-2 p-lg-0 mb-4 mb-lg-0">
        <div className="text-center">

          <Communicate width={150} height={150} className="text-white mb-3"/>
          <h5 className="text-secondary-light">communication</h5>
          </div>
        </div>
        <div className="col-12 col-lg-2 p-lg-0 mb-4 mb-lg-0">
        <div className="text-center">
            <Swap width={150} height={150} className="text-white mb-3"/>
          <h5 className="text-secondary-light">flexibility</h5>
          </div>
        </div>
        <div className="col-12 col-lg-2 p-lg-0 mb-4 mb-lg-0">
        <div className="text-center">
          <Support  width={150} height={150} className="text-white mb-3"/>
          <h5 className="text-secondary-light">supportive</h5>
          </div>
        </div>
        <div className="col-12 col-lg-2 p-lg-0 mb-4 mb-lg-0">
        <div className="text-center">
          <Award width={150} height={150} className="text-white mb-3"/>
          <h5 className="text-secondary-light">quality</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const Learnings = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="mb-2">learnings</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <p>729 Solutions rose to this challenge and showed our skills as Zendesk integration experts. Whenever an
            issue surfaced, our client could trust us to quickly find a workable solution that would keep the project
            right on track.</p>
        </div>
        <div className="col-12 col-lg-4  mt-4 mt-lg-0">
          <p>We learned that it’s possible for an extremely busy call center to see a full 50% reduction in costs after
            the smooth implementation of a customer service management platform. This client’s customer satisfaction
            with the call center increased from 70% to 90%, which is quite impressive.</p>
        </div>
        <div className="col-12 col-lg-4  mt-4 mt-lg-0">
          <p>Based on our own client satisfaction research regarding this client and others, we found that some of 729
            Solutions’ highest areas of client satisfaction include experience/expertise, communication, flexibility,
            supportiveness, and quality.</p>
        </div>
      </div>
    </div>
  </section>
);

const PureFlixQuote = () => (
  <section className="bg-dark py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <Quote
            dark
            name="Debbie Stackis"
            company="COO Pure Flix"
            quote="729 were very knowledgeable and helped us through our Zendesk transition. It was just good to be able to ask someone questions as they arose."/>
        </div>
        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center mt-5 mt-lg-0">
          <div className="w-50">
            <StaticImage
              src="../../images/clients/pureflix.png"
              alt="pureflix logo"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Pure Flix"
        description="729 Solutions helped Pure Flix navigate a challenging zendesk initegration for its busy customer call center."
      />
      <PortfolioHeader/>
      <PureFlixHeader/>
      <ProjectGoals/>
      <PartnerQuote/>
      <DevelopmentLanguagesAndNeeds/>
      <ProjectSkills/>
      <Learnings/>
      <PureFlixQuote/>
      <ProjectForm location={location}/>
      <PortfolioFeed className="my-6"/>
    </Layout>
  );
};

export default Index;
